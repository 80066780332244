.serv{
	background-color: #fff0f2;
	color: #901321;
	border-color:#fff0f2;
}

ul {
    list-style-type: none;
}



.loca{
	background-color: #ddf6dd;
	color: #901321;
	border-color: #ddf6dd
}

.fix{
      overflow-x: auto;
}