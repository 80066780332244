/*.App {
  text-align: center;
 { background:
     radial-gradient(black 3px, transparent 4px),
     radial-gradient(black 3px, transparent 4px),
     linear-gradient(#fff 4px, transparent 0),
     linear-gradient(45deg, transparent 74px, transparent 75px, #a4a4a4 75px, #a4a4a4 76px, transparent 77px, transparent 109px),
     linear-gradient(-45deg, transparent 75px, transparent 76px, #a4a4a4 76px, #a4a4a4 77px, transparent 78px, transparent 109px),
     #fff;
     background-size: 109px 109px, 109px 109px,100% 6px, 109px 109px, 109px 109px;
     background-position: 54px 55px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
 }}*/

.fix{
      overflow-x: auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}


.form {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.leggo {
  width: 500px;
}

.title {
  font-size: 120px
}

p{
 font-family: 'Great Vibes', cursive;
 font-family: 'GFS Neohellenic', sans-serif;
/*font-family: 'Lato', sans-serif;*/
/*font-family: 'Playfair Display', serif;*/
/*font-family: 'Heebo', sans-serif;*/
/*font-family: 'Domine', serif;*/
font-family: 'Cinzel', serif; 
/*font-family: 'Alegreya', serif;!!!!!*/
/*font-family: 'Old Standard TT', serif; !!!!!*/
/*font-family: 'Josefin Slab', serif;*/
/*font-family: 'Vidaloka', serif;*/
/*font-family: 'Rasa', serif; !!!!*/
/*font-family: 'Boogaloo', cursive;*/
/*font-family: 'Rambla', sans-serif;*/
/*font-family: 'Berkshire Swash', cursive;*/
/*font-family: 'Oranienbaum', serif;*/
/*font-family: 'Cinzel Decorative', cursive;*/
/*font-family: 'Calligraffitti', cursive;*/
/*font-family: 'Rye', cursive;*/
/*font-family: 'Andada', serif;*/
/*font-family: 'IM Fell Double Pica', serif;*/
   color:  #d4af37;

  font-weight: 100;
  background: white;
  
 
}

/*vision*/
h2{
  font-family: 'Dancing Script', cursive;
  color:  #d4af37;
 
  font-weight: 100;
  background: white;
}

.desc{
  color:  #d4af37;
  font-family: 'Dancing Script', cursive; 
}



.four{
  color:  grey;
  font-size: 19px;
  font-weight: 100;
  background: white;
  font-family: 'Libre Caslon Display', serif;
  margin: 0;
}

.one {
  font-family: 'Satisfy', cursive;
  font-size: 40px;
}



.three{
   font-family: 'Kaushan Script', cursive;
  color:  #d4af37;

}
h6{
  font-family: 'Cinzel', serif; 
  font-weight: 100;
  color: grey;
  margin-bottom: 0px;
  margin-top: 50px
}



h3{
  font-family: 'Cinzel', serif; 
  color:  #d4af37;
  font-size: 20px;
  font-weight: 500;
  background: white;
 
}

h4{
  font-family: 'Cinzel', serif; 
  color:  #d4af37;
  font-size: 19px;
  font-weight: 500;
  background: white;
 
}

#root {
  width: 100%;
}


/*.dog {
    background-size: 30px 100px;
   
}*/

/*
button {
  
  border-color: grey;
  font-size: 20px;
  color: grey;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 300;
  margin: 300;
  text-align: center;
  float: center;
 font-family: 'Roboto Condensed', sans-serif;
}*/


button:hover {background-color: #e7e7e7;}

.vanalign{
  display: block;
}








