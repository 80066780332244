.App {
  text-align: center;
}


.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}


.form {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.gold-text {
color:#c3a343;
-webkit-text-fill-color: transparent;
background: -webkit-linear-gradient(transparent, transparent),
url(http://example.com/gold-texture-image.png) repeat;
background: -o-linear-gradient(transparent, transparent);
-webkit-background-clip: text;
}


.newform{
	margin: 0 auto;
	
}

.two {
  font-family: 'Kaushan Script', cursive;
  color: #901321;
  flex:none;
}

/*.cardsz{
	width: 70em;
}
*/

.conback{
	color: #901321;
	background-color: #e9fbff;
	border-color: #901321;
}

.fix{
      overflow-x: auto;
}